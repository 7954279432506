import React from 'react';
import Layout from "../components/layout"
import {graphql} from 'gatsby'
import PostStyles from "./post.module.scss"
import SEO from "../components/seo.js"
export const query=graphql`
query($slug:String!){
    markdownRemark(fields:{
        slug:{
            eq:$slug
        }
    })
    {
        frontmatter{
            title
            date
            description
            keywords
        }
        html
    }
}

`

const Blog = (props) => {
    return ( 
        <Layout>
            <SEO
            title={props.data.markdownRemark.frontmatter.title}
            description={props.data.markdownRemark.frontmatter.description}
            keywords={props.data.markdownRemark.frontmatter.keywords.split(",")}
            />
            <div className={PostStyles.post}>
            
            <h1 className={PostStyles.title}>{props.data.markdownRemark.frontmatter.title}</h1>
            <p className={PostStyles.date}>{props.data.markdownRemark.frontmatter.date}</p>
            <div className="w-75 mx-auto">
            <div className={PostStyles.text}  dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}>
            </div>
            </div>
            </div>
        </Layout>


     );
}
 
export default Blog;